
<template>
  <vx-card>
    <!-- ZOOM POP UP -->
    <template>
      <!-- ZOOM IMAGE POP UP -->
      <vs-popup
        classContent="popup-example label-font "
        title="Stock Image"
        :active.sync="zoomPopupActive"
      >
        <div style="width:400px; margin: 5px auto;">
          <img class="responsive rounded" :src="zoomzoom" style="width:95%;" />
        </div>
      </vs-popup>
      <!-- POP UP -->
    </template>
    <!-- REMOVE POP UP -->
    <template>
      <!-- REMOVE POP UP -->
      <vs-popup
        classContent="popup-example label-font"
        title="ลบข้อมูล"
        :active.sync="removePopupActive"
      >
        <!--  รหัส  -->
        <vs-row style="font-weight:bold; margin-top:30px;" class="row-form" vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
            class="form-label px-2"
            style="padding-left: 20px 30px;"
          >ลบสินค้า {{this.removeCode}}</vs-col>
        </vs-row>

        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-end"
            vs-align="center"
            vs-lg="12"
            vs-sm="12"
            vs-xs="12"
          >
            <vs-button @click="remove()" style="margin:10px;" color="success" type="filled">ตกลง</vs-button>
            <vs-button
              @click="closeRemovePanel()"
              style="margin:10px;"
              color="danger"
              type="filled"
            >ยกเลิก</vs-button>
          </vs-col>
        </vs-row>
      </vs-popup>
      <!-- POP UP -->
    </template>
    <!-- POP UP ASSIGN ITEM TO BRANCH -->
    <template>
      <vs-popup
        classContent="popup-example"
        title="แบ่งสินค้าให้ตามสาขา"
        :active.sync="assignItemPanelActive"
      >
        <!-- ROW OF ASSIGN ITEM TO BRANCH -->
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="5"
            vs-sm="5"
            vs-xs="12"
          >
            <h4>สินค้ามีทั้งหมด {{currentAmountInstock}} ชิ้น</h4>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="5"
            vs-sm="5"
            vs-xs="12"
          >
            <h5 style="color:#7ED321;">มีสินค้ายังไม่ระบุสาขา {{remainingItems}} ชิ้น</h5>
          </vs-col>
        </vs-row>
        <div
          class="grid-layout-container alignment-block"
          style="vertical-align:text-top ;width:100%;padding-bottom:40px;"
        >
          <vs-row style="margin-top:30px;" vs-w="12" v-for="(item, index) in branches" :key="index">
            <vs-col
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
              style="padding-right:30px;"
            >
              <b>{{item.branchName}}</b>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="6"
            >
              จำนวน &nbsp;&nbsp;&nbsp;
              <!-- หาจาก assign  item -->
              {{amountOfAssignItem(item.branchId) }}
              <!-- <vs-input-number
                style="width:50px; color: red;"
                min="0"
                :max="remainingItems + assignItems[index].inStock"
                v-model="assignItems[index].inStock"
              />-->
            </vs-col>
          </vs-row>
        </div>

        <!--------------------------------------------------------------------->
        <!-- <div style="border-top: 1px solid #E3E3E3; margin-bottom:30px; "></div> -->
        <!--------------------------------------------------------------------->

        <!--  ปุ่ม -->
        <vs-row
          vs-w="12"
          vs-type="flex"
          vs-justify="flex-end"
          style="padding : 20px 20px; background-color: #F8F8F8; border: 0.5px rgb(248, 248, 248);"
        >
          <vs-button
            style="background-color:#4A4A4A !important; width:120px;"
            @click="assignItemPanelActive=false;"
            type="filled"
          >ปิด</vs-button>
          <!-- <vs-button
            style="background-color:#7ED321 !important; margin-left:15px;"
            type="filled"
          >ดำเนินการ</vs-button>-->
          <vs-button
            :href="getRedirectLink()"
            target="blank"
            style=" margin-left:15px;"
            color="warning"
            type="filled"
          >ไปแก้ไขจำนวน</vs-button>
        </vs-row>
        <!--------------------------------------------------------------------->
      </vs-popup>
    </template>
    <!-- POP UP CHECK QUEUE -->
    <template>
      <vs-popup
        classContent="popup-example"
        title="เช็คคิวสินค้า"
        :active.sync="checkqueuePanelActive"
      >
        <!-- ROW OF CHECKQUEUE -->
        <vs-row
          style="display: flex;width: 100%;padding: 20px 0px;margin-bottom: 30px;border-bottom: 1px solid #e4e4e4;"
          vs-w="12"
          v-for="(checkitem, qindex) in checkqueueList"
          :key="qindex"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-xs="3"
          >
            <div style="vertical-align: text-top;">
              <img
                class="responsive rounded"
                :src="getFullpath(checkitem.mainImage)"
                alt="user-upload"
                style="width:100px;"
              />
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="9"
            vs-sm="9"
            vs-xs="9"
          >
            <div
              class="grid-layout-container alignment-block"
              style="vertical-align:text-top ;width:100%;"
            >
              <!--  ข้อมูลสินค้า -->
              <vs-row class="row-form" vs-w="12">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="3"
                  vs-sm="3"
                  vs-xs="3"
                  class="form-label px-2"
                >ข้อมูลสินค้า</vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="9"
                  vs-sm="9"
                  vs-xs="9"
                >
                  <div
                    class="grid-layout-container alignment-block"
                    style="vertical-align:text-top ;width:100%;"
                  >
                    <vs-row vs-w="12">
                      <b>{{checkitem.name}}</b>
                    </vs-row>
                    <vs-row
                      vs-w="12"
                      style="font-size: 11px;"
                    >{{checkitem.code}} (สาขา{{thisBranch.branchName}})</vs-row>
                  </div>
                </vs-col>
              </vs-row>

              <!--  คิวเช่า -->

              <!-- จำนวนสินค้าในสต็อค-->
              <vs-row class="row-form" vs-w="12" style="margin-top:20px;">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="3"
                  vs-sm="3"
                  vs-xs="3"
                  class="form-label px-2"
                >จำนวนในสต็อค</vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="5"
                  vs-sm="5"
                  vs-xs="5"
                  style="z-index : 20 !important;"
                >
                  <span
                    style="font-size:18px; font-family:SukhumvitSet-Bold; color:#7ED321; "
                  >มีสินค้าเหลือ {{checkitem.branchStock[0].inStock }} ชุด</span>
                </vs-col>
              </vs-row>
              <!--  ช่วงเวลา -->
              <vs-row class="row-form" vs-w="12" style="margin-top:20px;">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="3"
                  vs-sm="3"
                  vs-xs="3"
                  class="form-label px-2"
                >ช่วงเวลา</vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="7"
                  vs-sm="7"
                  vs-xs="7"
                  style="z-index : 20 !important;"
                >
                  <!-- เช็คคิวสินค้า -->
                  <!-- <HotelDatePicker
                    :i18n="$store.state.thai"
                    format="YYYY-MM-DD"
                    startDate="2001-01-01"
                    :startingDateValue="theCheckIn"
                    :endingDateValue="theCheckOut"
                    @check-in-changed="qCheckIn($event,qindex)"
                    @check-out-changed="qCheckOut($event,qindex)"
                  /> -->
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="2"
                  vs-sm="2"
                  vs-xs="2"
                >
                  <vs-button
                    vs-type="flex"
                    vs-align="center"
                    vs-justify="center"
                    style="width:100%; padding-left:0; padding-right:0; "
                    color="rgb(255, 114, 131)"
                    type="filled"
                    @click="checkQueue(checkitem, qindex)"
                  >
                    <span style=" vertical-align:middle;">เช็คคิว</span>
                  </vs-button>
                </vs-col>
              </vs-row>
              <!--  ต้องการเช่า -->
              <vs-row class="row-form" vs-w="12" style="margin-top:20px;">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="3"
                  vs-sm="3"
                  vs-xs="3"
                  class="form-label px-2"
                  style="z-index : 10 !important;"
                >ต้องการเช่า</vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="2"
                  vs-sm="2"
                  vs-xs="2"
                  style="z-index : 10 !important;"
                >
                  <vs-input v-model="reservedq[qindex]" />
                </vs-col>

                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="7"
                  vs-sm="7"
                  vs-xs="7"
                >
                  <div
                    v-if="checkqq[qindex] === 'ไม่ติด'"
                    class="grid-layout-container alignment-block"
                    style="vertical-align:text-top ;width:100%;"
                  >
                    <div class="sm-btn-green">ไม่ติดคิว</div>
                  </div>
                  <div
                    v-if="checkqq[qindex] === 'ติด'"
                    class="grid-layout-container alignment-block"
                    style="vertical-align:text-top ;width:100%;"
                  >
                    <div class="sm-btn-red">ติดคิว</div>
                  </div>
                </vs-col>
              </vs-row>

              <!-- แสดงผล -->
              <vs-row class="row-form" vs-w="12" style="margin-top:20px;">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="10"
                  vs-sm="10"
                  vs-xs="10"
                  class="form-label px-2"
                >
                  <div
                    class="grid-layout-container alignment-block"
                    style="vertical-align:text-top ;width:100%;"
                  >
                    <div v-if=" checkitem['dlist'].length === 0">
                      <h4 style="font-family:SukhumvitSet-Bold; color:#FF7283;">ไม่มีคิวเช่า</h4>
                    </div>
                    <div v-else>
                      <h4 style="font-family:SukhumvitSet-Bold; color:#7467EF;">คิวเช่า</h4>

                      <div v-for="(itm, keyy) in checkitem['dlist']" :key="keyy">
                        {{seq(keyy)}} {{getCustomerName(itm['orderHeaderId'])}} ตั้งแต่
                        <span
                          style="color:green;  "
                        >{{ formatDate(itm['startDate']) }}</span>
                        ถึง
                        <span
                          style="color:red;  "
                        >{{ formatDate(itm['endDate']) }}</span>
                      </div>
                    </div>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>

        <!--------------------------------------------------------------------->
        <!-- <div style="border-top: 1px solid #E3E3E3; margin-bottom:30px; "></div> -->
        <!--------------------------------------------------------------------->

        <!--  ปุ่ม -->
        <vs-row
          vs-w="12"
          vs-type="flex"
          vs-justify="flex-end"
          style="padding : 20px 20px; background-color: #F8F8F8; border: 0.5px rgb(248, 248, 248);"
        >
          <vs-button
            style="background-color:#4A4A4A !important; width:120px;"
            @click="closeCheckqueuePanel()"
            type="filled"
          >ยกเลิก</vs-button>
        </vs-row>
        <!--------------------------------------------------------------------->
      </vs-popup>
      <!-- POP UP -->
    </template>
    <!-- POP UP BORROW ITEM -->
    <template>
      <vs-popup classContent="popup-example" title="ขอยืมชุด" :active.sync="borrowPanelActive">
        <!-- ROW OF BORROW ITEM -->
        <!-- ROW OF CHECKQUEUE -->
        <vs-row
          style="display: flex;width: 100%;padding: 20px 0px;margin-bottom: 30px;border-bottom: 1px solid #e4e4e4;"
          vs-w="12"
          v-for="(bitem, bindex) in borrowList"
          :key="bindex"
        >
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="3"
            vs-sm="3"
            vs-xs="3"
          >
            <div style="vertical-align: text-top;">
              <img
                class="responsive rounded"
                :src="getFullpath(bitem.mainImage)"
                alt="user-upload"
                style="width:100px;"
              />
            </div>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="9"
            vs-sm="9"
            vs-xs="9"
          >
            <div
              class="grid-layout-container alignment-block"
              style="vertical-align:text-top ;width:100%;"
            >
              <!--  ข้อมูลสินค้า -->
              <vs-row class="row-form" vs-w="12">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="3"
                  vs-sm="3"
                  vs-xs="3"
                  class="form-label px-2"
                >ข้อมูลสินค้า</vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="9"
                  vs-sm="9"
                  vs-xs="9"
                >
                  <div
                    class="grid-layout-container alignment-block"
                    style="vertical-align:text-top ;width:100%;"
                  >
                    <vs-row vs-w="12">
                      <b>{{bitem.name}}</b>
                    </vs-row>
                    <vs-row vs-w="12" style="font-size: 11px;">{{bitem.code}}</vs-row>
                  </div>
                </vs-col>
              </vs-row>

              <!--  คิวเช่า -->

              <!-- สาขาที่ต้องการยืม -->
              <vs-row class="row-form" vs-w="12" style="margin-top:20px;">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="3"
                  vs-sm="3"
                  vs-xs="3"
                  class="form-label px-2"
                >สาขาที่ต้องการยืม</vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="5"
                  vs-sm="5"
                  vs-xs="5"
                  style="z-index : 20 !important;"
                >
                  <selection1
                    @result="selection1_result(bindex, ...arguments)"
                    :list="otherBranches"
                    id="branchId"
                    desc="branchName"
                  ></selection1>
                  <!-- <ul class="demo-alignment">
                    <li v-for="(b, ee) in otherBranches" :key="ee">
                      <vs-radio
                        v-model="branchRadio[bindex]"
                        :vs-value="b.branchId"
                      >{{b.branchName}}</vs-radio>
                    </li>
                  </ul>-->
                </vs-col>
              </vs-row>


              <!-- จำนวนสินค้าในสต็อค-->
              <vs-row class="row-form" vs-w="12" style="margin-top:20px;">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="3"
                  vs-sm="3"
                  vs-xs="3"
                  class="form-label px-2"
                >จำนวนในสต็อค</vs-col>
                <vs-col
                  v-if="checkRes[bindex] !== null && checkRes[bindex] !== undefined"
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="5"
                  vs-sm="5"
                  vs-xs="5"
                  style="z-index : 20 !important;"
                >
                  <span
                    style="font-size:18px; font-family:SukhumvitSet-Bold; color:#7ED321; "
                  >มีสินค้า {{checkRes[bindex].inStock }} ชุด</span>
                </vs-col>
                <vs-col
                  v-else
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="5"
                  vs-sm="5"
                  vs-xs="5"
                  style="z-index : 20 !important;"
                >
                  <span
                    style="font-size:18px; font-family:SukhumvitSet-Bold; color:#7ED321; "
                  >มีสินค้า 0 ชุด</span>
                </vs-col>
              </vs-row>

              <!--  ช่วงเวลา -->
              <vs-row class="row-form" vs-w="12" style="margin-top:20px;">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="3"
                  vs-sm="3"
                  vs-xs="3"
                  class="form-label px-2"
                >ช่วงเวลา</vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="7"
                  vs-sm="7"
                  vs-xs="7"
                  style="z-index : 20 !important;"
                >
                  <!-- เช็คคิวสินค้า -->
                  <!-- <HotelDatePicker
                    :i18n="$store.state.thai"
                    format="YYYY-MM-DD"
                    startDate="2001-01-01"
                    :startingDateValue="theCheckIn"
                    :endingDateValue="theCheckOut"
                    @check-in-changed="bCheckIn($event,bindex)"
                    @check-out-changed="bCheckOut($event,bindex)"
                  />-->
                  <!-- <functional-calendar
                    v-model="calendarCheckq"
                    :configs="$store.state.calendarConfigs"
                  ></functional-calendar>-->
                  <v-date-picker
                    style="width:100%;"
                    :locale="{ id: 'th', firstDayOfWeek: 2, masks: {  L: 'DD/MM/YYYY' } }"
                    mode="range"
                    v-model="calendarBorrow"
                  />
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="2"
                  vs-sm="2"
                  vs-xs="2"
                >
                  <vs-button
                    vs-type="flex"
                    vs-align="center"
                    vs-justify="center"
                    style="width:100%; padding-left:0; padding-right:0; "
                    color="rgb(255, 114, 131)"
                    type="filled"
                    @click="chkq(bitem, bindex)"
                  >
                    <span style=" vertical-align:middle;">เช็คของ</span>
                  </vs-button>
                </vs-col>
              </vs-row>

              <!--  ต้องการเช่า -->
              <vs-row class="row-form" vs-w="12" style="margin-top:20px;">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="3"
                  vs-sm="3"
                  vs-xs="3"
                  class="form-label px-2"
                  style="z-index : 10 !important;"
                >ต้องการเช่า</vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="2"
                  vs-sm="2"
                  vs-xs="2"
                  style="z-index : 10 !important;"
                >
                  <vs-input v-model="reservedq[bindex]" />
                </vs-col>

                <vs-col
                  v-if="checkRes !== null && checkRes !== undefined && checkRes.length > 0"
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="7"
                  vs-sm="7"
                  vs-xs="7"
                >
                  <div
                    v-if="checkRes[bindex].result === 1"
                    class="grid-layout-container alignment-block"
                    style="vertical-align:text-top ;width:100%;"
                  >
                    <div class="sm-btn-green">{{checkRes[bindex].desc}}</div>
                  </div>
                  <div
                    v-if="checkRes[bindex].result === 0"
                    class="grid-layout-container alignment-block"
                    style="vertical-align:text-top ;width:100%;"
                  >
                    <div class="sm-btn-red">{{checkRes[bindex].desc}}</div>
                  </div>
                </vs-col>
                <vs-col
                  v-else
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="7"
                  vs-sm="7"
                  vs-xs="7"
                ></vs-col>
              </vs-row>
              <!--  ปุ่มยืมของ -->
              <vs-row class="row-form" vs-w="12" style="margin-top:20px;">
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-offset="5"
                  vs-lg="7"
                  vs-sm="7"
                  vs-xs="7"
                  v-if="borValid === 1 && checkRes[bindex] !== null && checkRes[bindex] !== undefined && checkRes.length > 0"
                >
                  <div
                    v-if="checkRes[bindex].result === 1"
                    class="grid-layout-container alignment-block"
                    style="vertical-align:text-top ;width:100%;"
                  >
                    <div
                      @click="submitBorrow(blist[bindex], bindex)"
                      class="sm-btn-black"
                    >กดเพื่อยืมชุด</div>
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>

        <!--  ปุ่ม -->
        <vs-row
          vs-w="12"
          vs-type="flex"
          vs-justify="flex-end"
          style="padding : 20px 20px; background-color: #F8F8F8; border: 0.5px rgb(248, 248, 248);"
        >
          <vs-button
            style="background-color:#4A4A4A !important; width:120px;"
            @click="closeBorrowPanel()"
            type="filled"
          >ยกเลิก</vs-button>
          <!-- <vs-button
            style="background-color:#7ED321 !important; margin-left:15px;"
            type="filled"
          >ดำเนินการ</vs-button>-->
        </vs-row>
        <!--------------------------------------------------------------------->
      </vs-popup>
    </template>
    <!-- HEADER -->
    <!-- BUTTON BORROW CHECKQUEUE ADDITEM -->
    <vs-row vs-w="12">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-lg="6"
        vs-sm="6"
        vs-xs="12"
      >
        <vs-row vs-w="12">
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="8"
            vs-sm="8"
            vs-xs="12"
          >
            <!-- HEADER -->
            <!-- <HotelDatePicker
              :i18n="$store.state.thai"
              format="YYYY-MM-DD"
              startDate="2001-01-01"
              :startingDateValue="startingDateValue"
              :endingDateValue="endingDateValue"
              @check-in-changed="newCheckIn($event)"
              @check-out-changed="newCheckOut($event)"
              showYear
            />-->
            <functional-calendar v-model="calendarData" :configs="$store.state.calendarConfigs"></functional-calendar>
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="flex-start"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="12"
          >
            <!-- {{displayDateText(startPoint)}} -->
            <vs-button
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
              style="width:100%; padding-left:0; padding-right:0; "
              color="rgb(255, 114, 131)"
              type="filled"
              @click="checkqueueListPanel($event, selectedList)"
              :disabled="!btnActive"
            >
              <span>
                <i
                  style="font-size:18px; margin-right:5px;vertical-align:middle;"
                  class="material-icons"
                >update</i>
              </span>
              <span style=" vertical-align:middle;">เช็คคิวสินค้า</span>
            </vs-button>
          </vs-col>
        </vs-row>
      </vs-col>

      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-lg="6"
        vs-sm="6"
        vs-xs="12"
      >
        <vs-row vs-w="12">
          <!-- <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="12"
          >
            <vs-button
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
              style="width:100%; padding-left:0; padding-right:0; "
              color="#7ED321"
              type="filled"
              v-on:click="newItem()"
              :disabled="!btnActive"
            >
              <span>
                <i
                  style="font-size:18px; margin-right:5px;vertical-align:middle;"
                  class="material-icons"
                >redeem</i>
              </span>
              <span style=" vertical-align:middle;">เพิ่มสินค้า</span>
            </vs-button>
          </vs-col>-->

          <!--
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="12"
          >
            <vs-button
              @click="multiplePrintPDF()"
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
              style="width:100%; padding-left:0; padding-right:0; "
              color="#9B9B9B"
              type="filled"
              :disabled="!btnActive"
            >
              <span>
                <i
                  style="font-size:18px; margin-right:5px;vertical-align:middle;"
                  class="material-icons"
                >local_offer</i>
              </span>
              <span style=" vertical-align:middle;">สร้างการ์ดสินค้า</span>
            </vs-button>
          </vs-col>
          -->
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-lg="4"
            vs-sm="4"
            vs-xs="12"
          >
            <vs-button
              vs-type="flex"
              vs-align="center"
              vs-justify="center"
              style="width:100%; padding-left:0; padding-right:0; "
              color="#F5A623"
              type="filled"
              @click="reload()"
              :disabled="!btnActive"
            >
              <span>
                <i
                  style="font-size:18px; margin-right:5px;vertical-align:middle;"
                  class="material-icons"
                >cloud_download</i>
              </span>
              <span style=" vertical-align:middle;">reload</span>
            </vs-button>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>

    <!-- BRANCHES -->

    <vs-row vs-w="12">
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-lg="10"
        vs-sm="10"
        vs-xs="10"
        style="margin-top:20px;"
      >
        <!-- <ul v-if="$acl.check('admin') || $acl.check('owner')" class="demo-alignment"> -->
        <!-- <ul class="demo-alignment">
          <li>
            <div @mouseup="checkAll()" class="sm-btn-yellow">ทั้งหมด</div>
          </li>
          <li v-for="(b, index) in branches" :key="index">
            <vs-checkbox
              @mouseup="checkBranch(b)"
              v-model="branchCheckbox"
              :vs-value="b.branchName"
            >{{ b.branchName }}</vs-checkbox>
          </li>
        </ul>-->
        <ul class="demo-alignment">
          <li v-for="(b, index) in branches" :key="index" style="margin-right:10px !important;">
            <vs-radio v-model="branchrad" :vs-value="b.branchId">{{b.branchName}}</vs-radio>
          </li>
        </ul>
      </vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-lg="2"
        vs-sm="2"
        vs-xs="12"
      >
        <!-- <vs-button
          vs-type="flex"
          vs-align="center"
          vs-justify="center"
          style="width:100%; padding-left:0; padding-right:0; "
          color="#F5A623"
          type="filled"
          @click="reload()"
          :disabled="!btnActive"
        >
          <span>
            <i
              style="font-size:18px; margin-right:5px;vertical-align:middle;"
              class="material-icons"
            >cloud_download</i>
          </span>
          <span style=" vertical-align:middle;">reload</span>
        </vs-button>-->
      </vs-col>
    </vs-row>

    <!-- BRANCHES -->
    <!-- PAGES  จำนวนข้อมูลที่แสดง  -->
    <vs-row
      class="row-form"
      vs-type="flex"
      vs-justify="flex-end"
      vs-w="12"
      style="margin-top:30px;"
    >
      <vs-col
        vs-type="flex"
        vs-justify="flex-end"
        vs-align="center"
        vs-lg="1"
        vs-sm="1"
        vs-xs="1"
        class="form-label px-2"
      >จำนวน</vs-col>
      <vs-col
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="center"
        vs-lg="1"
        vs-sm="1"
        vs-xs="1"
        class="form-label px-2"
      >
        <v-select
          style="width: 100%;"
          v-model="pageitemSelected"
          :options="pageitemOptions"
          :select="pageitemSelected"
        ></v-select>
      </vs-col>
    </vs-row>
    <!--  PAGES -->

    <!-- HEADER -->

    <!-- TABLE -->
    <vs-table
      :sst="true"
      @change-page="handleChangePage"
      style="z-index:1;"
      pagination
      :max-items="pageitemSelected"
      search
      :data="itemsInStock"
    >
      <!-- TABLE HEADER -->
      <template style="z-index:1 !important;" slot="thead">
        <vs-th>
          <vs-checkbox v-if="selectStatus===0" v-model="selectAll" @click="selectAllOrders($event)"></vs-checkbox>
          <vs-checkbox
            v-else-if="selectStatus===1"
            icon-pack="feather"
            icon="icon-check"
            v-model="selectAll"
            @click="selectAllOrders($event)"
          ></vs-checkbox>
          <vs-checkbox
            v-else
            icon-pack="feather"
            icon="icon-minus"
            v-model="selectAll"
            @click="selectAllOrders($event)"
          ></vs-checkbox>
        </vs-th>
        <vs-th sort-key="incharge">รูปตัวอย่าง</vs-th>
        <vs-th sort-key="orderNumber">สินค้า</vs-th>
        <!-- <vs-th sort-key="productItem">ชื่อสินค้า</vs-th> -->
        <vs-th sort-key="customerName">จำนวน</vs-th>
        <vs-th sort-key="rentalTotal">ราคาเช่า/ชิ้น</vs-th>
        <vs-th sort-key="bail">ค่าประกัน/ชิ้น</vs-th>
        <!-- <vs-th sort-key="pickupDate">ประเภท</vs-th>
        <vs-th sort-key="returnDate">สี</vs-th>-->
        <vs-th sort-key="returnDate">วันที่เพิ่ม</vs-th>
        <vs-th sort-key="rentalStat">
          สถิติ
          <br />การเช่า
        </vs-th>
        <vs-th v-if="$acl.check('admin')" sort-key="itemStatus">สถานะ</vs-th>
        <vs-th v-if="$acl.check('admin')">การจัดการ</vs-th>
      </template>

      <!-- TABLE BODY -->
      <template style="z-index:1 !important;" slot-scope="{data}">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <!-- <vs-checkbox v-model="data[indextr].selected" @click="addSelectedList(indextr, $event)"></vs-checkbox> -->
            <vs-checkbox
              :data-id="tr.productItemId"
              v-model="data[indextr].selected"
              @click="addSelectedList(tr, $event)"
            ></vs-checkbox>
          </vs-td>
          <vs-td>
            <img
              @click="zoomimage(tr.mainImage)"
              class="responsive rounded"
              :src="getFullpath(tr.mainImage)"
              alt="user-upload"
              style="width:70px;"
            />
          </vs-td>
          <!-- itemName -->
          <vs-td style=" width:200px;">
            <span
              @click="editItem($event, tr.productItemId)"
              style="color: #2372CE; font-weight:bold;"
            >{{tr.name}}</span>
            <br />
            <b>ประเภท :</b>
            {{getCategoryName(tr.category)}}
            <br />
            <!-- &nbsp;&nbsp;&nbsp; -->
            <b>สี :</b>
            {{tr.color}}
            <br />
            <b>รหัส :</b>
            <b>{{getBarcode(tr.code)}}</b>

            <!-- แบ่งแยกกลุ่ม -->
            <div
              class="grid-layout-container alignment-block"
              style="vertical-align:text-top ;width:100%;"
            >
              <vs-row vs-w="12">
                <vs-col
                  v-if="$acl.check('admin')"
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="center"
                  vs-lg="6"
                  vs-sm="6"
                  vs-xs="12"
                >
                  <div
                    @click="addGroup(tr.groupId, tr.productItemId)"
                    class="sm-btn-cyan"
                  >เพิ่มกลุ่ม</div>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="4"
                  vs-sm="4"
                  vs-xs="12"
                  v-if="tr.group === 'c'"
                >
                  <div class="sm-btn-black">c</div>
                </vs-col>
                <vs-col
                  vs-type="flex"
                  vs-justify="flex-start"
                  vs-align="flex-start"
                  vs-lg="4"
                  vs-sm="4"
                  vs-xs="12"
                  v-if="tr.group === 'm'"
                >
                  <div class="sm-btn-yellow">m</div>
                </vs-col>
              </vs-row>
            </div>

            <!-- แบ่งแยกกลุ่ม -->

            <br />

            <i
              @click="borrowPanel($event,tr)"
              style="font-size:20px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; color:#5A8EE6; "
              class="material-icons iconhover"
            >repeat</i>
          </vs-td>
          <vs-td style="text-align:center; font-family: SukhumvitSet-Medium;">
            <b v-if="$acl.check('onlyStaff')">{{otherbranchstock(tr.productItemId)}}</b>
            <b v-if="$acl.check('admin')">{{tr.inStock}}</b>
            <br />

            <div v-if="$acl.check('admin')">
              <i
                @click="assignItemPanel($event,indextr,tr)"
                style="font-size:24px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; color:#5A8EE6; "
                class="material-icons iconhover"
              >store_mall_directory</i>
            </div>
          </vs-td>

          <vs-td style="padding-left:30px;">{{tr.rentalPrice}}</vs-td>
          <vs-td style="padding-left:30px;">{{tr.bail}}</vs-td>

          <!-- <vs-td>{{tr.itemCategory}}</vs-td>
          <vs-td>{{tr.itemColor}}</vs-td>-->
          <!-- <vs-td>{{tr.orderStatus}}</vs-td> -->
          <vs-td style="width:90px;">{{formatDate(tr.updateDateTime)}}</vs-td>
          <vs-td style="text-align: center;">{{tr.rentalStat}}</vs-td>
          <vs-td v-if="$acl.check('admin')" style="text-align: center;">
            <vs-switch @change="changeState(indextr)" color="success" v-model="tr.state">
              <span slot="on">เปิด</span>
              <span slot="off">ปิด</span>
            </vs-switch>
          </vs-td>

          <vs-td v-if="$acl.check('admin')">
            <i
              @click="editItem($event, tr.productItemId)"
              style="color: #5A8EE6;font-size:24px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons"
            >edit</i>
            <i
              @click="removeItem($event, indextr , tr)"
              style="color:#F63B39; font-size:24px; margin-right:5px;vertical-align:middle; padding:5px 5px; border-radius:10px; "
              class="material-icons"
            >delete_forever</i>
          </vs-td>

          <div style="width: 100%; background-color:pink; height:50px; "></div>
        </vs-tr>
      </template>
    </vs-table>
    <!-- TABLE -->

    <br />
  </vx-card>
</template>

<script>
import Prism from "vue-prism-component";
import vSelect from "vue-select";
// import HotelDatePicker from "vue-hotel-datepicker";
import Datepicker from "vuejs-datepicker";
import shapeFormat from "../../mixins/shapeFormat.js";
import ProductService from "./Services/ProductService";
import { setTimeout } from "timers";
import Selection1 from "./Components/Selection1";

const productService = new ProductService();
// import { log } from "util";
// import VueRangedatePicker from "vue-rangedate-picker";
// import VueHotelDatepicker from "@northwalker/vue-hotel-datepicker";

var pdfMake = require("pdfmake/build/pdfmake.js");
var pdfFonts = require("pdfmake/build/vfs_fonts.js");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

//ต้องระบุตามชื่อของ ไฟล์ font
pdfMake.fonts = {
  Sukhumvit: {
    normal: "SukhumvitSet-Medium.otf",
    bold: "SukhumvitSet-Bold.otf"
  },
  kanit: {
    normal: "Kanit-Medium.ttf",
    bold: "Kanit-Bold.ttf"
  },
  THSarabunNew: {
    normal: "THSarabunNew.ttf",
    bold: "THSarabunNew-Bold.ttf",
    italics: "THSarabunNew-Italic.ttf",
    bolditalics: "THSarabunNew-BoldItalic.ttf"
  },
  Roboto: {
    normal: "Roboto-Regular.ttf",
    bold: "Roboto-Medium.ttf",
    italics: "Roboto-Italic.ttf",
    bolditalics: "Roboto-MediumItalic.ttf"
  }
};

// import VueHotelDatepicker from "@northwalker/vue-hotel-datepicker";

export default {
  mixins: [shapeFormat],
  data() {
    return {
      calendarData: {},
      calendarCheckq: {},
      calendarBorrow: {},
      // bid: "",
      // onCalendar: false,
      branchrad: "",
      borValid: 0,
      checkRes: [],
      borrowReq: [],
      borrowBranchId: "",
      branchRadio: [],
      otherBranches: [],
      firstOtherBranch: {},
      reservedq: [],
      checkqq: [],
      name: "",
      name2: ["check1", "check2", "check3"],
      thisBranch: {},
      dlist: [],
      itemsInBranch: [],
      currentBranch: "", // BRANCH ID
      otherBranch: [],
      startingDateValue: new Date(),
      endingDateValue: null,
      theCheckIn: new Date(),
      theCheckOut: null,
      zoomPopupActive: false,
      zoomzoom: "",
      lastPage: 0,
      pageitemSelected: 16,
      pageitemOptions: [8, 16, 24, 32, 40, 48],
      removeId: "",
      removePopupActive: false,
      removeIndex: -1,
      removeCode: "",
      currentUser: "guest",
      // allBranchCheckbox: true,
      btnActive: true,
      panelId: "",
      categories: [],
      code3Digit: "000",
      loadedlist: null,
      anewCheckIn: "2019-04-01",
      checkInDate: "2019-04-01",
      checkOutDate: "2019-06-01",
      checkq: "",
      tryselect: "เมืองทองธานี",
      maximumBorrowedItemAmount: 0,
      borrowedItemAmount: 0,
      selectedBranch: "เลือกสาขา",
      branchOptions: ["เมืองทองธานี", "ราชพฤกษ์"],
      selectedDate: {
        start: "",
        end: ""
      },
      remainingItems: 0,
      currentAmountInstock: 0,
      assignItems: [],
      assignItemPanelActive: false,
      indexToAssign: 0,
      borrowPanelActive: false,
      borrowList: [],
      blist: [],
      checkqueuePanelActive: false,
      checkqueueList: [],
      startDate: undefined,

      instart: undefined,
      inend: undefined,
      startPoint: "2019-05-01",
      endPoint: "2019-05-03",
      checkqueueItem: {
        itemImage: "http://localhost:8088/images/item/defaultimage.png",
        // itemImage:
        //   "https://www.google.com/url?sa=i&source=images&cd=&cad=rja&uact=8&ved=2ahUKEwirvNWq2NLiAhWeiXAKHRt0Bb0QjRx6BAgBEAU&url=%2Furl%3Fsa%3Di%26source%3Dimages%26cd%3D%26ved%3D%26url%3Dhttps%253A%252F%252Fwww.allure.com%252Fstory%252Ffashion-nova-plus-size-controversy%26psig%3DAOvVaw1PNvitmeBB7YkiDCzMkVuq%26ust%3D1559836059922993&psig=AOvVaw1PNvitmeBB7YkiDCzMkVuq&ust=1559836059922993",
        itemName: "Item Name",
        AmountInStock: 0,
        rentalPrice: 0,
        bail: 0,
        itemCategory: "ไม่ระบุ",
        itemColor: "ไม่ระบุ",
        updateDate: "2019-04-23",
        rentalStat: 0,
        itemStatus: false
      },
      rentalQueue: [
        {
          customerName: "คุณเบน",
          startDate: "2019-04-23",
          endDate: "2019-04-25"
        },
        {
          customerName: "คุณจอน",
          startDate: "2019-05-12",
          endDate: "2019-05-15"
        }
      ],

      itemsInStock: [],

      branchCheckbox: ["ลาดพร้าว", "เมืองทองธานี"],
      branches: ["ลาดพร้าว", "เมืองทองธานี"],

      statusToReturn: "คืนชุดแล้ว",
      statusToReturnOption: [
        { text: "คืนชุดแล้ว", value: "คืนชุดแล้ว" },
        { text: "ยกเลิก", value: "ยกเลิก" }
      ],
      popupActive: false,
      searchDate: "",
      selectedList: [],
      selectAll: false,
      selectStatus: 0, // {0: ไม่เลือกเลย  1: เลือกทั้งหมด 2: เลือกบางอัน}
      fromSelectAll: false,
      date: null,
      pickupDate: null,

      payment_select: "เงินสด",
      payment_option: [
        { text: "เงินสด", value: "เงินสด" },
        { text: "โอนเงิน", value: "โอนเงิน" },
        
        { text: "บัตรเครดิต", value: "บัตรเครดิต" }
      ],
      tableList: [
        "vs-th: Component",
        "vs-tr: Component",
        "vs-td: Component",
        "thread: Slot",
        "tbody: Slot",
        "header: Slot"
      ],
      originalList: []
    };
  },
  computed: {
    allBranchCheckbox: {
      get: function() {
        var all = true;

        if (this.branchCheckbox.length === this.branches.length) {
          all = true;
        } else {
          all = false;
        }

        return all;
      },
      set: function() {}
    }
    //   barcodeValue: {
    //     get: function() {
    //       var year_th = "";

    //       if (this.latestyear.value < 10) {
    //         year_th = "0" + this.latestyear.value.toString();
    //       } else {
    //         year_th = this.latestyear.value.toString();
    //       }

    //       console.log("<<<<<<<<< @COMPUTED >>>>>>>>>>>>>>>>");

    //       console.log("year_th >>> ", year_th);

    //       console.log(
    //         "this.categorySelected.value  >>> ",
    //         this.categorySelected.value
    //       );
    //       console.log(
    //         "this.textureSelected.value >>> ",
    //         this.textureSelected.value
    //       );

    //       console.log("this.itemCode3Digit >>> ", this.itemCode3Digit);

    //       console.log("this.sizeSelected >>> ", this.sizeSelected);

    //       return (
    //         year_th +
    //         this.categorySelected.value +
    //         this.textureSelected.value +
    //         this.gender +
    //         this.itemCode3Digit +
    //         this.sizeSelected
    //       );
    //     }
    //   }
  },
  created() {
    this.calendarBorrow.start = new Date();
    this.calendarBorrow.end = this.addDay(new Date(), 1);
  },
  async mounted() {
    // LOAD CATEGORY

    this.$vs.loading({ type: "radius" });

    this.currentUser = JSON.parse(localStorage.getItem("username"));
    var responseCategory;
    try {
      responseCategory = await this.$http.get(
        this.$store.state.apiURL + "/api/category", this.$store.state.auth.apiHeader
      );
      this.categories = await responseCategory.data;
    } catch (error) {
      this.shapeFormat.noticeError(error);
    }

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    // LOAD BRANCH

    var responseBranch = null;
    try {
      responseBranch = await this.$http.get(
        this.$store.state.apiURL + "/api/branch" , this.$store.state.auth.apiHeader
      );
    } catch (error) {
      responseBranch = null;
      this.shapeFormat.noticeError(error);
    }

    if (responseBranch.status !== null) {
      this.branches = await responseBranch.data.filter(
        x =>
          x.state === true && x.branchId !== this.$store.state.auth.mybranchid
      );
      // FIND OTHER BRANCH
      this.otherBranches = await responseBranch.data.filter(
        x =>
          x.state === true && x.branchId !== this.$store.state.auth.mybranchid
      );
      this.firstOtherBranch = await responseBranch.data.find(
        x =>
          x.state === true && x.branchId !== this.$store.state.auth.mybranchid
      );
    }

    // LOAD Stock
    var responseStock = null;
    try {
      responseStock = await this.$http.get(
        this.$store.state.apiURL + "/api/stock",  this.$store.state.auth.apiHeader
      );
      console.log("STOCK RESPONSEStock >>> ", responseStock.data);
    } catch (error) {
      responseStock = null;
      this.shapeFormat.noticeError(error);
      // appropriately handle the error
    }

    if (responseStock !== null) {
      this.thestock = await responseStock.data;
    }

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // LOAD ITEM
    var response = null;
    try {
      response = await this.$http.get(
        this.$store.state.apiURL + "/api/productitem" ,  this.$store.state.auth.apiHeader
      );
    } catch (error) {
      response = null;
    }

    if (response !== null) {
      // this.noticeSuccess("ทำการบันทึกสำเร็จ");

      setTimeout(() => {
        this.$vs.loading.close();
      }, 1000);
      this.itemsInStock = await response.data;
      this.originalList = await response.data;
      console.log("PRODUCT response", response.data);
    } else {
      setTimeout(() => {
        this.$vs.loading.close();
        this.noticeError("เกิดความผิดพลาดขึ้น");
      }, 1000);
    }

    // FIND CURRENT BRANCH ID !!
    this.currentBranch = await this.otherBranches[0].branchId;

    // FIND OTHER BRANCH
    // this.otherBranch = this.branches.filter(
    //   br => br.branchId !== this.$store.state.auth.mybranchid
    // );

    if (this.$store.state.auth.permission === "staff") {
      this.itemsInStock = await this.removeZero(this.itemsInStock);
      this.originalList = await this.removeZero(this.originalList);
    }

    // หา branch จากสิทธิ์ผู้ใช้งาน

    // console.log("ITEMSTOCK AFTER TRANSFORM >>> ", this.itemsInStock);

    console.log(" this.currentBranch>>> ", this.currentBranch);

    // >>>>>>>>>>> FOR CHECK QUEUE >>>>>>>>>>>>>>

    // this.branchRadio[0] = await this.firstOtherBranch.branchId;
    // console.log(" this.branchRadio >>> ", this.branchRadio);

    this.orders = await this.loadAllOrderShare();
    this.dlist = await this.loadAllDetailShare();
    this.thisBranch = await this.getBranch(this.currentBranch);
    this.branchrad = await this.currentBranch;

    // >>>>>>>>>>> FOR CHECK QUEUE >>>>>>>>>>>>>>
  },

  methods: {
    async addGroup(groupid, productid) {
      if (groupid === null || groupid === "" || groupid === undefined) {
        window.open("/backoffice/add-group/" + productid, "_blank");
      } else {
        window.open("/backoffice/add-group/" + groupid, "_blank");
      }
    },

    async submitBorrow(item, index) {
      this.$vs.loading({ type: "radius" });
      console.log(
        "submitBorrow start >>>>>>>>> ",
        this.formatDate(item.pickupDate),
        "endDate >>>>>> ",
        this.formatDate(item.returnDate)
      );

      var theItem = {
        productItemId: item.productItemId,
        approve: 0,
        startDate: this.formatDate(item.pickupDate),
        endDate: this.formatDate(item.returnDate),
        fromBranch: this.$store.state.auth.mybranchid,
        toBranch: this.branchRadio[index],
        qty: this.reservedq[index],
        updateBy: this.currentUser
      };
      var res = null;
      try {
        res = await this.$http.post(
          this.$store.state.apiURL + "/api/borrow",
          theItem,
           this.$store.state.auth.apiHeader
        );
      } catch (err) {
        res = null;
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeError("เกิดข้อผิดพลาดไม่สามารถส่งข้อมูลขอยืมชุดได้");
        }, 500);
      }
      if (res !== null) {
        setTimeout(() => {
          this.$vs.loading.close();
          this.noticeSuccess("ส่งข้อมูลเพื่อขอยืมชุดแล้ว");
          this.borrowPanelActive = false;
        }, 500);
      }
    },
    selection1_result(ind, value) {
      this.blist[ind].branchId = value;
      this.branchRadio[ind] = value;
      this.borValid = 0;
      console.log(
        "ind >>> ",
        ind,
        "this.blist[ind].branchId >>> ",
        this.blist[ind].branchId
      );
    },
    getCustomerName(orderid) {
      // console.log("getCustomerName >>>> ", orderid);

      var ord = this.orders.find(x => x.orderHeaderId === orderid);

      if (ord === null || ord === undefined) {
        return "";
      } else {
        return ord.customerName;
      }
    },
    removeZero(list) {
      var tmp = [];
      var i = 0;
      if (this.$acl.check("staff")) {
        for (i = 0; i < list.length; i++) {
          if (this.otherbranchstock(list[i].productItemId) > 0) {
            tmp.push(list[i]);
          }
        }
      } else {
        for (i = 0; i < list.length; i++) {
          tmp.push(list[i]);
        }
      }

      return tmp;
    },
    seq(ind) {
      var seqnum = Number(ind);
      if (!isNaN(seqnum)) {
        return (seqnum + 1).toString() + " .";
      } else {
        return "0 .";
      }
    },
    async getBranch(id) {
      var response = null;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/branch/" + id , this.$store.state.auth.apiHeader
        );
      } catch (error) {
        response = null;
        this.noticeError(error);
      }

      return await response.data;
    },
    async getABranch(id) {
      var x = this.otherBranches.filter(n => n.branchId === id);
      if (x.length === 0) {
        return null;
      } else {
        return x[0];
      }

      // var response = null;
      // try {
      //   response = await this.$http.get(
      //     this.$store.state.apiURL + "/api/branch/" + id , this.$store.state.auth.apiHeader
      //   );
      // } catch (error) {
      //   response = null;
      //   this.noticeError(error);
      // }
      // return await response.data;
    },
    async loadProduct() {
      var response = null;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/productitem" ,
           this.$store.state.auth.apiHeader
        );
      } catch (error) {
        response = null;
        this.noticeError(error);
      }

      return await response.data;
    },
    async loadStock(branchId) {
      var response;
      try {
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/productitem/branch/" + branchId,
           this.$store.state.auth.apiHeader
        );
        if (response.status === 200) {
          this.branchStock = await response.data;
        }
      } catch (error) {
        this.noticeError(error);
        // console.log("error", error);
      }

      return await response.data;
    },
    qCheckIn: function(newDate, index) {
      this.checkqueueList[index].startDate = newDate;
    },
    qCheckOut: function(newDate, index) {
      this.checkqueueList[index].endDate = newDate;
    },

    bCheckIn: function(newDate, index) {
      this.borValid = 0;
      console.log("this.borValid ", this.borValid);
      this.blist[index].pickupDate = newDate;
    },
    bCheckOut: function(newDate, index) {
      this.borValid = 0;
      console.log("this.borValid ", this.borValid);
      this.blist[index].returnDate = newDate;
    },

    newCheckIn: function(newDate) {
      // console.log("A new check in >>> ", newDate);
      this.theCheckIn = newDate;
    },
    newCheckOut: function(newDate) {
      // console.log("A new check out >>> ", newDate);
      this.theCheckOut = newDate;
    },

    otherbranchstock(productId) {
      var st = this.thestock.find(
        x => x.productItemId === productId && x.branchId === this.currentBranch
      );
      if (st === null || st === undefined || st === "") {
        return 0;
      } else {
        return st.inStock;
      }
    },
    zoomimage(image) {
      this.zoomzoom = this.$store.state.imageStore + image;
      this.zoomPopupActive = true;
    },

    handleChangePage() {
      console.log("The user changed the page ");
    },

    checkBranch() {
      this.itemsInStock = [];
    },
    checkAll() {
      this.branchCheckbox = [];
      this.itemsInStock = [];
      // console.log("this.branches >>> ", this.branches);
      // console.log("this.allBranchCheckbox >>> ", this.allBranchCheckbox);

      if (this.allBranchCheckbox === true) {
        // console.log("IF >>> ");
      } else {
        for (var i = 0; i < this.branches.length; i++) {
          this.branchCheckbox.push(this.branches[i].branchName);
        }
        this.resetlist();

        // console.log("this.originalList >>> ", this.originalList);
      }
    },
    resetlist() {
      this.itemsInStock = [];
      for (var j = 0; j < this.originalList.length; j++) {
        this.itemsInStock.push(this.originalList[j]);
      }
    },
    getBarcode(code) {
      if (
        code === "" ||
        code === null ||
        code === undefined ||
        code === "01WSW001S"
      ) {
        return "ไม่ได้บันทึก";
      } else {
        return code;
      }
    },

    async reload() {
      this.btnActive = !this.btnActive;
      this.$vs.loading({ type: "radius" });

      // LOAD ITEM
      try {
        // RELOAD STOCK
        var responseStock = await this.$http.get(
          this.$store.state.apiURL + "/api/stock",  this.$store.state.auth.apiHeader
        );
        this.thestock = await responseStock.data;

        // RELOAD ITEMS
        var response = null;
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/productitem" ,  this.$store.state.auth.apiHeader
        );
      } catch (error) {
        response = null;
      }

      if (response === null) {
        setTimeout(() => {
          // this.noticeError("ไม่สำเร็จ");

          this.$vs.loading.close();
        }, 500);
        this.btnActive = !this.btnActive;
      } else {
        console.log("ALREADY 200");
        setTimeout(async () => {
          this.$vs.loading.close();
          this.itemsInStock = await response.data;
          this.originalList = await response.data;
          if (this.$store.state.auth.permission === "staff") {
            this.itemsInStock = this.removeZero(response.data);
            this.originalList = this.removeZero(response.data);
          }
          // this.noticeSuccess("บันทึกเสร็จสิ้น");
        }, 500);

        this.btnActive = !this.btnActive;
      }
    },

    async reloadWithNoLoading() {
      this.btnActive = !this.btnActive;

      // LOAD ITEM
      try {
        // RELOAD STOCK
        var responseStock = await this.$http.get(
          this.$store.state.apiURL + "/api/stock",  this.$store.state.auth.apiHeader
        );
        this.thestock = await responseStock.data;

        // RELOAD ITEMS
        var response = null;
        response = await this.$http.get(
          this.$store.state.apiURL + "/api/productitem",  this.$store.state.auth.apiHeader
        );
      } catch (error) {
        response = null;
      }

      if (response === null) {
        setTimeout(() => {
          // this.noticeError("ไม่สำเร็จ");
          // this.$vs.loading.close();
        }, 500);
        this.btnActive = !this.btnActive;
      } else {
        console.log("ALREADY 200");
        setTimeout(async () => {
          // this.$vs.loading.close();
          this.itemsInStock = await response.data;
          this.originalList = await response.data;
          if (this.$store.state.auth.permission === "staff") {
            this.itemsInStock = this.removeZero(response.data);
            this.originalList = this.removeZero(response.data);
          }
          // this.noticeSuccess("บันทึกเสร็จสิ้น");
        }, 500);

        this.btnActive = !this.btnActive;
      }
    },

    // async reload() {
    //   this.btnActive = !this.btnActive;
    //   this.$vs.loading({ type: "radius" });

    //   // LOAD ITEM
    //   try {
    //     // RELOAD STOCK
    //     var responseStock = await this.$http.get(
    //       this.$store.state.apiURL + "/api/stock"
    //     );
    //     this.thestock = await responseStock.data;

    //     // RELOAD ITEMS
    //     const response = await this.$http.get(
    //       this.$store.state.apiURL + "/api/productitem"
    //     );

    //     // this.noticeSuccess("ทำการบันทึกสำเร็จ");
    //     if (response.status === 200) {
    //       console.log("ALREADY 200");
    //       setTimeout(() => {
    //         this.$vs.loading.close();
    //         console.log("LOADING CLOSED");
    //       }, 2000);
    //       this.itemsInStock = await response.data;
    //       this.originalList = await response.data;
    //       this.btnActive = !this.btnActive;
    //       console.log("SUCCESS this.btnActive ", this.btnActive);
    //     } else {
    //       console.log("SUCCESS this.btnActive ", this.btnActive);
    //       setTimeout(() => {
    //         this.$vs.loading.close();
    //       }, 1000);
    //     }
    //     console.log("response", response.data);
    //   } catch (error) {
    //     this.noticeSuccess(error);
    //     setTimeout(() => {
    //       this.$vs.loading.close();
    //     }, 1000);
    //     this.btnActive = !this.btnActive;
    //     console.log("error", error);
    //     console.log("ERROR this.btnActive ", this.btnActive);
    //   }

    //   if (this.$store.auth.state.permission === "staff") {
    //     this.itemsInStock = await this.removeZero(this.itemsInStock);
    //     this.originalList = await this.removeZero(this.originalList);
    //   }
    // },
    async removeItem(event, ind, item) {
      event.stopPropagation();
      this.removePopupActive = true;
      this.removeIndex = ind;
      this.removeCode = item.code;
      this.removeId = item.productItemId;
    },
    async remove() {
      var resp = await this.$http.delete(
        this.$store.state.apiURL + "/api/productitem/" + this.removeId ,  this.$store.state.auth.apiHeader
      );
      // var resp = await productService.remove(this.$vs.notice, this.removeId);
      if (resp.status === 200) {
        this.noticeSuccess("ลบ " + this.removeCode + " สำเร็จ");
        this.itemsInStock.splice(this.removeIndex, 1);
        this.closeRemovePanel();
      } else {
        this.noticeSuccess("มีข้อผิดพลาด");
      }
    },
    closeRemovePanel() {
      this.removePopupActive = false;
      this.removeIndex = -1;
      this.removeCode = "";
      this.removeId = "";
    },
    getFullpath(mainImage) {
      if (
        mainImage === undefined ||
        mainImage === null ||
        mainImage === "" ||
        mainImage === this.$store.state.imageStore
      ) {
        return this.$store.state.defaultImage;
      } else {
        return this.$store.state.imageStore + mainImage;
      }
    },
    changeState(ind) {
      // var state = !this.itemsInStock[ind].state;
      // this.itemsInStock[ind].state = state;
      console.log(
        "ind >>> ",
        ind,
        " this.itemsInStock[ind].state >>> ",
        this.itemsInStock[ind].state
      );

      productService.update(
        this.itemsInStock[ind],
        this.itemsInStock[ind].productItemId
      );
    },
    getCategoryName(codename) {
      var c = this.categories.find(x => x.code === codename);

      var categoryName = "";
      if (c === null || c === undefined) {
        categoryName = "ไม่มี";
      } else {
        categoryName = c.description;
      }

      return categoryName;
    },
    async newItem() {
      console.log("NEW ITEM");

      try {
        var response = await this.$http.post(
          this.$store.state.apiURL + "/api/productitem",
          {
            name: "New-Item",
            code: "01WSW001S",
            category: "C",
            texture: "C",
            size: "S",
            yearth: 1,
            gender: "W",
            itemAmount: 1,
            rentalPrice: 0,
            bail: 0,
            itemStatus: "เปิด",
            webCategory: "",
            webHotItem: "ไม่ใช่",
            webItem: "n",
            itemCode3Digit: "000",
            color: "",
            newRecord: true,
            temporary: false,
            state: true,
            updateBy: this.currentUser,
            createBy: this.currentUser
          },
           this.$store.state.auth.apiHeader
        );

        var fresposeStatus = await response.status;
        console.log("fresposeStatus >>> ", fresposeStatus);

        var fresposeProductId = await response.data;
        console.log("AFTER response >>> ", response);

        if (fresposeStatus === 200) {
          var branchResp = await this.$http.post(
            this.$store.state.apiURL + "/api/stock",
            {
              branchId: "",
              productItemId: fresposeProductId,
              inStock: 0
            },  this.$store.state.auth.apiHeader

          );
          var sres = await branchResp.data;
          console.log("branchResp >>> ", sres);
        }

        // Save to stock

        // Redirect to create stock
        if ((await branchResp.status) === 200) {
          await this.$router.push("/backoffice/create-item/" + response.data);
        }
      } catch (error) {
        this.noticeError(error);
      }
    },
    editItem(event, productItemId) {
      event.stopPropagation();
      window.open("/backoffice/create-item/" + productItemId, "_blank");
      // this.$router.push("/backoffice/create-item/" + productItemId);
    },
    onDateSelected: function(daterange) {
      this.selectedDate = daterange;
    },
    // FOR PRINT CARD
    arrayBufferToBase64(buffer) {
      var binary = "";
      var bytes = [].slice.call(new Uint8Array(buffer));

      bytes.forEach(b => (binary += String.fromCharCode(b)));

      return window.btoa(binary);
    },

    async convertFileToDataURLviaFileReader(url) {
      let resp = await fetch(url);

      console.log("convertFileToDataURLviaFileReader >>> ", resp);

      var base64Flag = await "data:image/png;base64,";
      var imageStr = this.arrayBufferToBase64(await resp.arrayBuffer());
      var imageurl = base64Flag + imageStr;
      return imageurl;
    },
    // async singlePrintPDF(event, printItem) {
    //   event.stopPropagation();
    //   var printList = [];
    //   printList.push(printItem);
    //   await this.printPDF(printList);
    // },

    // async multiplePrintPDF() {
    //   this.$vs.loading({ type: "radius" });
    //   try {
    //     await this.printPDF(this.selectedList);
    //     setTimeout(() => {
    //       this.$vs.loading.close();
    //     }, 500);
    //   } catch (err) {
    //     setTimeout(() => {
    //       this.$vs.loading.close();
    //       this.noticeError(err);
    //     }, 500);
    //   }
    // },

    // async printPDF(printingList) {
    //   printingList = printingList.filter(f => f.newRecord === false);

    //   if (
    //     printingList.length === null ||
    //     printingList.length === 0 ||
    //     printingList.length === undefined
    //   ) {
    //     return;
    //   }

    //   var dd = {
    //     // a string or { width: number, height: number }
    //     pageSize: "A4",

    //     // by default we use portrait, you can change it to landscape if you wish

    //     // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
    //     pageMargins: [10, 0, 10, 10],
    //     content: [],

    //     defaultStyle: {
    //       font: "Sukhumvit"
    //     },
    //     styles: {
    //       header: {
    //         fontSize: 11,
    //         alignment: "center"
    //       },
    //       anotherStyle: {
    //         italics: true,
    //         alignment: "right"
    //       },
    //       itemnameStyle: {
    //         alignment: "center",
    //         fontSize: 11
    //       },
    //       itemcodeStyle: {
    //         alignment: "center",
    //         fontSize: 11,
    //         bold: true
    //       },
    //       barcodeStyle: {
    //         alignment: "center"
    //       },
    //       costStyle: {
    //         alignment: "center",
    //         fontSize: 11,
    //         bold: true,
    //         color: "#444444"
    //       },
    //       rentalStyle: {
    //         fontSize: 14,
    //         bold: true,
    //         color: "red"
    //       }
    //     }
    //   };

    //   var COL_NUM = 4;
    //   var IMG_WIDTH = 120;
    //   var IMG_HEIGHT = 144;
    //   // var BARCODE_WIDTH = 150;
    //   for (var ind = 0; ind < printingList.length; ind++) {
    //     var CONTENT_COLUMN = Math.ceil((ind + 1) / COL_NUM) * 2 - 1;
    //     console.log(" BEGIN [", ind, "]    >>>>   ", dd.content);
    //     if (ind % COL_NUM === 0) {
    //       console.log("INSIDE ADD COLUMN");

    //       if (ind >= COL_NUM && ind % (COL_NUM * 2) === 0)
    //         dd.content.push({ text: "\n\n", pageBreak: "before" });
    //       else dd.content.push({ text: "\n\n" });
    //       // var returnline = {
    //       //   text: "\n\n HELLOOOOOOOO",
    //       //   pageOrientation: "portrait",
    //       //   pageBreak: "after"
    //       // };

    //       var newcolumn = { columns: [] };
    //       dd.content.push(newcolumn);

    //       // dd.content.push(testline);
    //     }
    //     // if (ind % 8 === 0) {
    //     //   dd.content.push({ text: ">>>>>>>>>>>>>> \n\n" });
    //     // }

    //     var barcodeimage = printingList[ind].barcodeImage;
    //     var response_barcode = await this.convertFileToDataURLviaFileReader(
    //       this.$store.state.barcodeStore + barcodeimage
    //     );

    //     // MainImage ไม่ต้องต่อ URLข้างหน้าแล้ว เพราะว่าตอนโหลดมาตอนแรกต้องใช้กับ list ของ stock เลย
    //     // ทำมาตั้งแต่ตอน  mounted แล้ว
    //     var mainimage = this.getFullpath(printingList[ind].mainImage);

    //     var response_mainimage;
    //     console.log("mainimage >>>> ", mainimage);

    //     if (
    //       mainimage === "" ||
    //       mainimage === null ||
    //       mainimage === this.$store.state.imageStore ||
    //       mainimage === undefined
    //     ) {
    //       response_mainimage = await this.convertFileToDataURLviaFileReader(
    //         this.$store.state.defaultImage
    //       );
    //     } else {
    //       response_mainimage = await this.convertFileToDataURLviaFileReader(
    //         mainimage
    //       );
    //     }

    //     // if (response_mainimage === null || response_mainimage === undefined) {
    //     //   this.noticeError("ไม่สามารถพิมพ์ได้");
    //     //   break;
    //     // }

    //     var response_oval = await this.convertFileToDataURLviaFileReader(
    //       this.$store.state.defaultStore + "oval.png"
    //     );

    //     if (
    //       printingList[ind].name === null ||
    //       printingList[ind].name === undefined
    //     ) {
    //       printingList[ind].name === "";
    //     } else {
    //       if (printingList[ind].name.length > 20) {
    //         printingList[ind].name = printingList[ind].name.substring(0, 20);
    //       }
    //     }

    //     // console.log("Show mainimage >>> ", mainimage);
    //     // console.log("Show response_mainimage >>> ", response_mainimage);

    //     var newrec = {
    //       style: "tableExample",
    //       table: {
    //         body: [
    //           [
    //             {
    //               stack: [
    //                 {
    //                   text: "\n",
    //                   style: "header"
    //                 },
    //                 {
    //                   image: await response_oval,
    //                   width: 25,
    //                   alignment: "center"
    //                 },
    //                 {
    //                   text: "\n",
    //                   style: "header"
    //                 },
    //                 {
    //                   image: await response_mainimage,
    //                   width: IMG_WIDTH,
    //                   height: IMG_HEIGHT,
    //                   alignment: "center"
    //                 },
    //                 {
    //                   text: "\n" + printingList[ind].name, // itemName
    //                   style: "itemnameStyle"
    //                 },
    //                 {
    //                   image: await response_barcode,
    //                   style: "barcodeStyle",
    //                   width: 130,
    //                   height: 53,
    //                   alignment: "center"
    //                 },
    //                 // {
    //                 //   image: await response_barcode,
    //                 //   width: IMG_WIDTH,
    //                 //   alignment: "center"
    //                 // },
    //                 // {
    //                 //   text: printingList[ind].itemName, // itemCode
    //                 //   style: "itemcodeStyle"
    //                 // },
    //                 {
    //                   text: [
    //                     { text: "ราคาเช่า :  ", style: "costStyle" },
    //                     {
    //                       text: printingList[ind].rentalPrice,
    //                       style: "rentalStyle"
    //                     }, // rentalPrice
    //                     { text: " บาท ", style: "costStyle" }
    //                   ]
    //                 },
    //                 {
    //                   text: [
    //                     { text: "ค่าประกันชุด :  ", style: "costStyle" },
    //                     {
    //                       text: printingList[ind].bail,
    //                       style: "costStyle"
    //                     }, // bail
    //                     { text: " บาท \n\n", style: "costStyle" }
    //                   ]
    //                 }
    //               ]
    //             }
    //           ]
    //         ]
    //       }
    //     };
    //     // console.log("dd.content >>> ", dd.content[CONTENT_COLUMN]);

    //     dd.content[CONTENT_COLUMN].columns.push(newrec);

    //     // if (ind % 8 === 7) {
    //     //   dd.content.push({
    //     //     text: "\n\n wintershop thailand \n"
    //     //   });
    //     //   // dd.content.push({
    //     //   //   text: "\n\n wintershop thailand \n",
    //     //   //   pageBreak: "after"
    //     //   // });
    //     // }
    //     console.log(" LAST [", ind, "]    >>>>   ", dd.content);
    //   } // for loop

    //   pdfMake.createPdf(dd).download("Namecard.pdf");
    // },

    // >>>>>>> CHECK QUEUE >>>>>>>>>
    async checkQueue(item, ind) {
      // เช็ค productItemId / this.startDate >=  /  =< this.endDate
      //  ต้องเทียบเป็นวัน
      // เอาจำนวนที่ยืม + จำนวนที่ยืมไปแล้ว` <= จำนวนที่มีใน Branch
      // console.log("ind :: ", ind, " ITEM CODE >>>> ", item.code);

      if (
        item.startDate === null ||
        item.startDate === undefined ||
        item.endDate === null ||
        item.endDate === undefined
      ) {
        this.noticeWarning(
          "ข้อมูลไม่ครบถ้วน",
          "ยังไม่ได้กำหนดวันรับชุด หรือวันคืนชุด"
        );

        return;
      }

      if (this.formatDate(item.startDate) > this.formatDate(item.endDate)) {
        this.noticeWarning(
          "ข้อมูลไม่ถูกต้อง",
          "การกำหนดวันรับชุดต้องอยู่ก่อนวันคืนชุด !!!"
        );

        return;
      }

      this.$set(this.checkqq, ind, "ไม่ติด");

      // หาว่ามีกี่วัน
      var diffDate = this.date_diff_indays(item.startDate, item.endDate);
      // Challenge by set notAvailable to FALSE
      // item.notAvailable = false;
      // this.checkqueueList[ind].message = "ไม่ติด";

      // console.log(">>>>>>>>>>>>> CHECK QUEUE >>>>>>>>>>>>>");

      // console.log("item.code >>>>>  ", item.code, " ind >>>>>  ", ind);

      var bst = this.thestock.filter(
        s =>
          s.productItemId === item.productItemId &&
          s.branchId === this.thisBranch.branchId
      );

      item["branchStock"] = bst;

      // item ที่ได้คือ item ใน branch stock
      if (bst === null || bst === undefined || bst.length <= 0) {
        // * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
        // * >>>>>>>>>>>   RESULT   >>>>>>>>>>>>>>>>
        // * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
        // this.checkqueueList[ind].notAvailable = true;
        // item.notAvailable = true;
        // this.checkqueueList[ind].message = "ติด";
        this.$set(this.checkqq, ind, "ติด");
      } else {
        // ถ้าของมีจำนวน 0
        // * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
        // * >>>>>>>>>>>   RESULT   >>>>>>>>>>>>>>>>
        // * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

        if (item["branchStock"]["inStock"] === 0) {
          // this.checkqueueList[ind].notAvailable = true;
          // item.notAvailable = true;
          // this.checkqueueList[ind].message = "ติด";
          this.$set(this.checkqq, ind, "ติด");
          console.log("item.code >>>>>  ", item.code, " ind >>>>>  ", ind);
        }
      }

      // item ที่ได้คือ item ใน branch stock
      // เอาค่า item.amountInBranch
      // ต้องเทียบทีละวัน

      console.log(" diffDate >>> ", diffDate);

      for (var da = 0; da < diffDate + 1; da++) {
        // เริ่มเทียบวันแรก ค่อยๆ เทียบไปทีละวันจนวันสุดท้าย
        // var theDate = this.addDay(new Date(this.startDate), da);
        var theDate = this.addDay(new Date(item.startDate), da);
        theDate = this.formatDate(theDate);
        // console.log("theDate >>> ", theDate);

        // console.log("[", da, "] : ITEM CHECK QUEUE >>>> ");

        // dlist คือ orderDetail List ทั้งหมด
        // state = true คือ queue ที่โดน LOCK อยู่ !!!
        var condition = this.dlist.filter(
          f =>
            f.productItemId === item.productItemId &&
            this.formatDate(f.startDate) <= theDate &&
            theDate <= this.formatDate(f.endDate) &&
            f.state === true &&
            // เอาแต่เฉพาะสินค้าของ Branch นี้
            f.branchId === this.thisBranch.branchId
        );

        // console.log(" theDate >>> ", theDate);
        // console.log(" condition >>> ", condition);

        var conditionSum = 0;
        for (var j = 0; j < condition.length; j++) {
          conditionSum = conditionSum + condition[j].reservedQuantity;
          console.log(
            "[da] [",
            da,
            "] [",
            j,
            "] : conditionSum >>>>>>> ",
            condition[j].reservedQuantity
          );
        }

        console.log("conditionSum >>>>>>> ", conditionSum);

        // ค่า reservedQuantity ทั้งหมด + ตัวมันเอง ต้องไม่มากกว่า ของที่มีใน stock
        var allReservedQuantity = 0;

        allReservedQuantity =
          Number(this.reservedq[ind]) + Number(conditionSum);

        // var allReservedQuantity = conditionSum;

        if (allReservedQuantity > item.branchStock[0].inStock) {
          // * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
          // * >>>>>>>>>>>   RESULT   >>>>>>>>>>>>>>>>
          // * >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
          // this.checkqueueList[ind].notAvailable = true;
          // item.notAvailable = true;
          // this.checkqueueList[ind].message = "ติด";
          this.$set(this.checkqq, ind, "ติด");

          break;
          // ถ้าไม่ว่างก็ หยุดได้เลย
        }
      }

      console.log("item.code >>>>>  ", item.code, " ind >>>>>  ", ind);

      console.log("this.checkqq >>>>>>> ", this.checkqq);
      console.log("IND  >>>>>>> ", ind);
      console.log("this.checkqueueList  >>>>>>> ", this.checkqueueList);
      // this.checkqueueList[ind] = item;
    },
    // >>>>>>> CHECK QUEUE >>>>>>>>>

    checkqueueListPanel(event, items) {
      // event.stopPropagation();

      this.checkqueuePanelActive = true;
      this.checkqueueList = [];
      this.blist = [];
      this.checkqq = [];
      this.reservedq = [];

      for (var i = 0; i < items.length; i++) {
        var anItem = {};
        anItem = items[i];
        anItem.startDate = this.theCheckIn;
        anItem.endDate = this.theCheckOut;
        // anItem.message = "ไม่ติด";
        anItem.reserved = 1;
        this.reservedq[i] = 1;
        this.checkqq[i] = "";

        // * >>>>>>>>>> หาจำนวนในสต็อค
        var bst = this.thestock.filter(
          s =>
            s.productItemId === anItem.productItemId &&
            s.branchId === this.thisBranch.branchId
        );

        anItem["branchStock"] = bst;
        // * >>>>>>>>>> หาจำนวนในสต็อค

        this.checkQueue(anItem, i);

        var detail = this.dlist.filter(
          dd =>
            dd.state === true &&
            dd.branchId === this.thisBranch.branchId &&
            dd.productItemId === items[i].productItemId
        );
        anItem["dlist"] = this.ascendingSortShare(detail, "startDate");
        console.log("[", i, "] : ", this.checkqueueList);
        this.checkqueueList.push(anItem);
      }

      console.log("this.checkqueueList >>> ", this.checkqueueList);
    },
    checkqueuePanel(event, anItem) {
      event.stopPropagation();
      this.checkqueuePanelActive = true;
      this.checkqueueList = [];
      this.checkqq = [];
      this.reservedq = [];
      // anItem.message = "ไม่ติด";
      anItem.reserved = 1;

      anItem.startDate = this.theCheckIn;
      anItem.endDate = this.theCheckOut;

      this.checkqq[0] = "";
      this.reservedq[0] = 1;

      // * >>>>>>>>>> หาจำนวนในสต็อค
      var bst = this.thestock.filter(
        s =>
          s.productItemId === anItem.productItemId &&
          s.branchId === this.thisBranch.branchId
      );

      anItem["branchStock"] = bst;
      // * >>>>>>>>>> หาจำนวนในสต็อค

      this.checkQueue(anItem, 0);

      this.checkqueueList.push(anItem);

      var detail = this.dlist.filter(
        dd =>
          dd.state === true &&
          dd.branchId === this.thisBranch.branchId &&
          dd.productItemId === anItem.productItemId
      );
      anItem["dlist"] = this.ascendingSortShare(detail, "startDate");

      // var ind = this.checkqueueList.length;
      // this.checkqueueList[ind - 1].startDate = this.theCheckIn;
      // this.checkqueueList[ind - 1].endDate = this.theCheckout;

      // console.log(this.displayDateText(this.startPoint));
    },

    closeCheckqueuePanel() {
      this.checkqueuePanelActive = false;
      this.checkqueueList = [];
      this.checkqq = [];
      this.reservedq = [];
    },

    async borrowPanel(event, anItem) {
      event.stopPropagation();
      console.log("borrow panel");
      // this.bid = this.generateRandom(5);
      // this.onCalendar = true;
      this.borrowPanelActive = true;
      this.checkqueueList = [];
      this.borrowList = [];
      this.borrowList.push(anItem);
      this.blist = [];
      this.checkqq = [];
      this.reservedq = [];
      this.checkRes = [];
      this.branchRadio = [];
      this.borValid = 0;

      if (anItem.returnDate === null || anItem.returnDate === undefined) {
        this.theCheckOut = this.theCheckIn;
      }

      this.theCheckIn = new Date();
      this.theCheckOut = new Date(this.addDay(this.theCheckIn, 1));

      // var checkItem = [];
      this.checkqq[0] = "";
      this.reservedq[0] = 1;
      this.branchRadio[0] = this.otherBranches[0].branchId;

      console.log(
        "this.theCheckIn",
        this.formatDateLocal(this.theCheckIn),
        "this.theCheckOut",
        this.formatDate(this.theCheckOut)
      );

      var item = {
        productItemId: anItem.productItemId,
         // *** แก้ไข 2020-11-14 PHASE1
        code: anItem.code,
        pickupDate: this.formatDate(this.calendarBorrow.start),
        returnDate: this.formatDate(this.calendarBorrow.end),
        branchId: this.branchRadio[0],
        qty: this.reservedq[0]
      };
      this.blist.push(item);

      console.log("this.blist >>> ", this.blist);

      var qres = await this.checkQueueShare(this.blist);
      this.checkRes.push(qres[0]);
      if (this.checkRes[0].result === 1) {
        this.borValid = 1;
      }
      console.log(" this.checkRes  >>> ", this.checkRes);

      // ****** CHECK QUEUE PROCESS
    },

    async borrowListPanel(list) {
      // event.stopPropagation();
      this.borrowPanelActive = true;

      // event.stopPropagation();

      this.checkqueueList = [];
      this.borrowList = [];
      this.blist = [];
      this.checkqq = [];
      this.reservedq = [];
      this.branchRadio = [];

      if (this.theCheckOut === null || this.theCheckOut === undefined) {
        this.theCheckOut = this.theCheckIn;
      }
      console.log("@@@@ BORROWLIST ::::: input list  >>>  ", list);
      for (var k = 0; k < list.length; k++) {
        this.branchRadio[k] = this.firstOtherBranch.branchId;
        this.reservedq[k] = 1;
        var item = {
          productItemId: list[k].productItemId,
          // *** แก้ไข 2020-11-14 PHASE1
        code: list[k].code,
          pickupDate: this.formatDate(this.calendarBorrow.start),
          returnDate: this.formatDate(this.calendarBorrow.end),
          branchId: this.branchRadio[k],
          qty: this.reservedq[k]
        };
        this.borrowList.push(list[k]);
        this.blist.push(item);
      }
      // console.log(" this.branchRadio[] >>>  ", this.branchRadio);
      // console.log(" this.reservedq  >>>  ", this.reservedq);
      // console.log(" this.blist  >>>  ", this.blist);

      var qres = await this.checkQueueShare(this.blist);
      this.checkRes = qres;
      for (var v = 0; v < this.checkRes.length; v++) {
        if (this.checkRes[v].result === 0) {
          this.borValid = 0;
        }
      }
      // console.log(" this.checkRes  >>>  ", this.checkRes);
    },
    closeBorrowPanel() {
      this.borrowPanelActive = false;
      // this.onCalendar = false;

      // event.stopPropagation();

      this.checkqueueList = [];
      this.borrowList = [];
      this.blist = [];
      this.checkqq = [];
      this.reservedq = [];
      this.branchRadio = [];
    },

  async chkq(item, ind) {
    if (this.calendarBorrow === null || this.calendarBorrow === undefined ||
        Object.entries(this.calendarBorrow).length === 0

      ) {
        this.noticeWarning(
          "ข้อมูลไม่ครบถ้วน",
          "คุณยังไม่ได้กำหนดวันรับชุดและวันคืนชุด"
        );
        return;
      }

      if (
        isNaN(Number(this.reservedq[ind])) ||
        this.numberFormat(this.reservedq[ind]) <= 0
      ) {
        this.noticeWarning(
          "ข้อมูลไม่ครบถ้วน",
          "คุณยังไม่ได้จำนวนสินค้าที่ต้องการขอยืม"
        );
        return;
      }

      var itemList = [];
      var theItem = {
        productItemId: item.productItemId,
        // *** แก้ไข 2020-11-14 PHASE1
        code: item.code,
        // pickupDate: this.blist[ind].pickupDate,
        // returnDate: this.blist[ind].returnDate,
        pickupDate: this.formatDate(this.calendarBorrow.start),
        returnDate: this.formatDate(this.calendarBorrow.end),
        branchId: this.branchRadio[ind],
        qty: this.numberFormat(this.reservedq[ind])
      };
      itemList.push(theItem);

      // console.log("itemList >>> ", itemList);

      this.checkRes = await this.checkQueueShare(itemList);
      if ((await this.checkRes) === null) {
        this.noticeWarning(
          "ข้อมูลไม่ครบถ้วน",
          "กรุณาตรวจสอบข้อมูลอีกครั้งและใส่ให้ครบถ้วน"
        );

        return;
      } else {
        if (this.checkRes[ind].result === 1) {
          this.borValid = 1;
        }
      }

      console.log("this.checkRes >>> ", this.checkRes);
    },

    //   closeCheckqueuePanel() {
    //   this.checkqueuePanelActive = false;
    //   this.checkqueueList = [];
    //   this.checkqq = [];
    //   this.reservedq = [];
    // },

    getRedirectLink() {
      return "/backoffice/create-item/" + this.panelId;
    },
    getRedirect(id) {
      return "/backoffice/create-item/" + id;
    },
    amountOfAssignItem(branchId) {
      var ai = this.assignItems.find(x => x.branchId === branchId);
      // console.log("this.assignItems >>> ", this.assignItems);
      // console.log("INPUT branchId >>> ", branchId);
      // console.log("ai >>> ", ai);

      if (ai != null && ai != undefined) {
        return ai.inStock;
      } else {
        return 0;
      }
    },
    assignItemPanel: function(event, ind, item) {
      event.stopPropagation();
      this.panelId = item.productItemId;
      this.assignItemPanelActive = true;
      this.assignItems = [];

      this.currentAmountInstock = item.inStock;

      var branchStock = this.thestock.filter(
        x => x.productItemId === item.productItemId
      );

      console.log("branchStock >>> ", branchStock);

      // this.assignItems = this.itemsInStock[ind].branchAmount;
      this.assignItems = branchStock;
      var assignedItemsSum = 0;
      for (var i = 0; i < branchStock.length; i++) {
        assignedItemsSum = assignedItemsSum + branchStock[i].inStock;
      }
      this.remainingItems = this.currentAmountInstock - assignedItemsSum;
      // console.log("Item In Stock  >>> ", this.itemsInStock);

      this.indexToAssign = ind;
    },

    resetpage() {
      this.lastPage = 0;
    },

    selectAllOrders: function(event) {
      event.stopPropagation();

      var cc = document.getElementsByClassName("is-current");
      var page_th = cc[0].getElementsByTagName("span")[0].innerText;
      var serow = document.querySelectorAll("input[data-id]");

      var codelist = [];

      for (var n = 0; n < serow.length; n++) {
        codelist.push(serow[n].getAttribute("data-id"));
      }

      var page_th_num = Number(page_th);
      if (isNaN(page_th_num)) {
        page_th_num = 1;
      }
      // console.log(" trow >>>>>>>>>>>>>>>>>>>>>>>>> ", trow[0].outerHTML);

      if (this.lastPage === 0) {
        //*** เข้ามาครั้งแรก
        this.lastPage = page_th_num;
      } else {
        //*** เปลี่ยนหน้าใหม่
        if (this.lastPage !== page_th_num) {
          this.selectStatus = 0;
          this.selectAll === false;
          this.lastPage = page_th_num;
          this.selectedList = [];
          for (ind = 0; ind < this.itemsInStock.length; ind++) {
            this.itemsInStock[ind].selected = false;
          }
        }
      }

      // ************************************************************************
      var ind = 0;

      // ************************************************************************
      //  คำนวณจำนวนที่ต้องเลือก
      // ************************************************************************

      // var first = (page_th_num - 1) * this.pageitemSelected + 1;
      // var last =
      //   (page_th_num - 1) * this.pageitemSelected + this.pageitemSelected;

      // if (last > this.itemsInStock.length) {
      //   last = this.itemsInStock.length;
      // }

      // ใช้แค่ code ที่ต้องการเลือกมา

      // init selectedList

      // this.selectedList = [];

      // for (var k = 0; k < codelist.length; k++) {
      //   var theind = this.itemsInStock.findIndex(xx => xx.code === codelist[k]);

      //   console.log("[", k, "] theind >>>>> ", theind);

      //   if (theind >= 0) {
      //     this.itemsInStock[theind].selected = true;
      //     this.selectedList.push(this.itemsInStock[theind]);
      //   }
      // }

      // ************************************************************************

      var k = 0;
      var theind = -1;
      if (this.selectAll === false && this.selectStatus === 1) {
        this.selectAll = true;
      }
      if (this.selectStatus === 0) {
        console.log("expected >>> 0");
        this.selectedList = [];

        // for (ind = first - 1; ind < last; ind++) {
        //   this.itemsInStock[ind].selected = true;
        //   this.selectedList.push(this.itemsInStock[ind]);
        // }

        for (k = 0; k < codelist.length; k++) {
          theind = -1;
          theind = this.itemsInStock.findIndex(
            xx => xx.productItemId === codelist[k]
          );

          console.log("[", k, "] theind >>>>> ", theind);

          if (theind >= 0) {
            this.itemsInStock[theind].selected = true;
            this.selectedList.push(this.itemsInStock[theind]);
          }
        }

        this.selectStatus = 1;
        console.log("go out with >>> 1");
      } else if (this.selectStatus === 2) {
        console.log("expected >>> 2");
        // this.selectedList = [];
        // for (ind = first - 1; ind < last; ind++) {
        //   this.itemsInStock[ind].selected = true;
        //   this.selectedList.push(this.itemsInStock[ind]);
        // }

        for (k = 0; k < codelist.length; k++) {
          theind = -1;
          theind = this.itemsInStock.findIndex(
            xx => xx.productItemId === codelist[k]
          );

          console.log("[", k, "] theind >>>>> ", theind);

          if (theind >= 0) {
            this.itemsInStock[theind].selected = true;
            this.selectedList.push(this.itemsInStock[theind]);
          }
        }
        this.selectStatus = 1;
        console.log("go out with >>> 1");
      } else if (this.selectStatus === 1) {
        console.log("expected >>> 1");
        // for (ind = first - 1; ind < last; ind++) {
        //   this.itemsInStock[ind].selected = false;
        // }
        // this.selectedList = [];

        for (k = 0; k < codelist.length; k++) {
          theind = -1;
          theind = this.itemsInStock.findIndex(
            xx => xx.productItemId === codelist[k]
          );

          console.log("[", k, "] theind >>>>> ", theind);

          if (theind >= 0) {
            this.itemsInStock[theind].selected = false;
          }
        }

        this.selectedList = [];

        this.selectStatus = 0;
        console.log("go out with >>> 0");
      }
      this.fromSelectAll = true;
      // ************************************************************************
      // **  BEGIN : OLD CODE
      // ************************************************************************

      // if (this.selectAll === false && this.selectStatus === 1) {
      //   this.selectAll = true;
      // }
      // if (this.selectStatus === 0 || this.selectStatus === 2) {
      //   // console.log("expected >>> 0 or 2");
      //   this.selectedList = [];
      //   for (ind = 0; ind < this.itemsInStock.length; ind++) {
      //     this.itemsInStock[ind].selected = true;
      //     this.selectedList.push(this.itemsInStock[ind]);
      //   }
      //   this.selectStatus = 1;
      // } else if (this.selectStatus === 1) {
      //   // console.log("expected >>> 1");
      //   for (ind = 0; ind < this.itemsInStock.length; ind++) {
      //     this.itemsInStock[ind].selected = false;
      //   }
      //   this.selectedList = [];
      //   this.selectStatus = 0;
      // }
      // this.fromSelectAll = true;

      // ************************************************************************
      // **  END : OLD CODE
      // ************************************************************************
    },
    addSelectedList(tr, event) {
      event.stopPropagation();
      // console.log(this.itemsInStock[indextt].selected);

      var indextt = this.itemsInStock.findIndex(
        x => x.productItemId === tr.productItemId
      );

      console.log(
        "indextt >>>> ",
        indextt,
        " this.itemsInStock[indextt] >>>> ",
        this.itemsInStock[indextt].code
      );

      if (!this.itemsInStock[indextt].selected) {
        this.itemsInStock[indextt].selected = true;
        this.selectedList.push(this.itemsInStock[indextt]);
        // console.log("ADDED");
      } else {
        if (this.selectedList) {
          this.itemsInStock[indextt].selected = false;
          var foundIndextt = this.selectedList.findIndex(
            x => x.orderNumber === this.itemsInStock[indextt].orderNumber
          );
          this.selectedList.splice(foundIndextt, 1);
          // console.log("REMOVED");
        }
      }
      this.itemsInStock[indextt].selected = !this.itemsInStock[indextt]
        .selected;
      // console.log(
      //   "SelectAll ==> " +
      //     this.selectAll +
      //     "TRUE STATUS  ===> " +
      //     this.selectStatus
      // );
      // console.log(this.itemsInStock);
      // console.log(this.selectedList);
    },
    formatPrice(value) {
      // let val = (value / 1).toFixed(2).replace(",", ".");
      console.log(value);

      // return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },

  components: {
    Prism,
    Datepicker,
    // VueRangedatePicker,
    // VueHotelDatepicker,
    "v-select": vSelect,
    // HotelDatePicker,
    Selection1
  },
  watch: {
    calendarData: {
      handler(value) {
        this.theCheckIn = this.formatDate(value.dateRange.start.date);
        this.theCheckOut = this.formatDate(value.dateRange.end.date);

        this.qCheckIn = this.formatDate(value.dateRange.start.date);
        this.qCheckOut = this.formatDate(value.dateRange.end.date);

        this.calendarCheckq = value;
      },
      deep: true
    },
    // branchRadio: async function(value) {
    //   // var bra = this.branches.find(b => b.branchId === value);
    //   this.borrowBranch = value;
    // },
    async branchrad(value) {
      // FIND THIS BRANCH
      this.thisBranch = this.getABranch(value);

      // FIND OTHER BRANCHES
      // this.otherBranches = await this.branches.filter(
      //   x => x.state === true && x.branchId !== this.thisBranch.branchId
      // );

      this.firstOtherBranch = this.branches.find(
        x => x.state === true && x.branchId === value
      );

      this.reloadWithNoLoading();

      console.log("BRANCH RAD >>> ", this.thisBranch);
    },
    reservedq: {
      handler() {
        this.borValid = 0;
      },
      deep: true
    },
    branchCheckbox: {
      handler() {
        if (this.branchCheckbox.length === this.branches.length) {
          this.resetlist();
          this.allBranchCheckbox = true;
        } else {
          if (this.branchCheckbox.length >= 1) {
            for (var i = 0; i < this.originalList.length; i++) {
              var saved = false;
              for (var j = 0; j < this.branchCheckbox.length; j++) {
                if (saved === true) continue;
                var branchname = this.branchCheckbox[j];
                // จะเอา branch ID
                var ab = this.branches.find(x => x.branchName === branchname);
                // หา ID ของ ใน stock
                if (ab != null && ab != undefined) {
                  var stock_branch = this.thestock.find(
                    s =>
                      s.branchId === ab.branchId &&
                      s.productItemId === this.originalList[i].productItemId &&
                      s.inStock > 0
                  );
                  // ได้ id ของ branch เอามาเทียบว่ามี ใน
                  if (stock_branch != null && stock_branch != undefined) {
                    this.itemsInStock.push(this.originalList[i]);
                    saved = true;
                  }
                }
              }
            }
          }
        }
      },
      deep: true
    },

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    assignItems: {
      handler() {
        // var assignedItemsSum = 0;
        // for (var i = 0; i < this.assignItems.length; i++) {
        //   assignedItemsSum = assignedItemsSum + this.assignItems[i].amount;
        // }
        // this.remainingItems = this.currentAmountInstock - assignedItemsSum;
        // console.log("WHEN CHANGE : Item In Stock  >>> ", this.itemsInStock);
      },
      deep: true
    }

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  }
};
</script>

<style>
.vs-con-tbody {
  z-index: 1;
}
input {
  width: 100%;
}
td {
  font-size: 12px;
}
.td-check {
  display: none;
}
.vs-select--input {
  font-size: 12px;
}
.input-select {
  font-size: 12px !important;
}

.iconhover:hover {
  background-color: #d0f3ff;
}

.vs-select--options span {
  font-size: 12px !important;
  width: 120%;
}
.change-to-return {
  background: #7ed321;
  border: 1px solid #549c04;
  border-radius: 16px;
  font-family: SukhumvitSet-Bold;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  padding: 3px 10px;
  margin-top: 10px;
}
.checkqueuelist {
  margin-top: 0;
}
.input-date {
  width: 100% !important;
}

.dropdown-toggle .clear {
  display: none;
}

.vs__selected-options .form-control {
  display: none;
}
.selected-tag {
  font-size: 12px;
}

/* VUE HOTEL DATEPICKUP */
.previous-arrow .offset-2 .disabled {
  display: block !important;
}

/* .calendar {
  position: relative !important;
} */

/************* VUE HOTEL DATEPICKUP **************/

.datepicker__input {
  font-size: 12px !important;
  line-height: 4 !important;
  padding: 4px 25px 2px !important;
}

.con-vs-popup .vs-popup {
  width: 750px;
}

.con-vs-popup .vs-popup .datepicker {
  left: -285px;
  padding: 0 10px;
  z-index: 9999 !important;
}

.datepicker.datepicker--closed {
  display: none;
}
.con-vs-popup .vs-popup .datepicker__inner {
  padding: 0;
  float: left;
  padding-bottom: 5px;
}

.con-vs-popup .vs-popup .datepicker__month-day {
  visibility: visible;
  text-align: center;
  margin: 0;
  border: 0;
  height: 25px;
  padding-top: 5px !important;
  vertical-align: middle;
}

.con-vs-popup .vs-popup .datepicker__week-row {
  border-bottom: 5px solid #fff;
  height: 20px;
}

.zoomimage {
  width: 400px;
}
.datepicker__tooltip {
  display: none !important;
}

.demo-alignment {
  vertical-align: middle;
}

li {
  margin: 0 !important;
}
</style>
